import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import { BET_SLIP_STATE, TOP_EVENTS_TYPES, AI_OUTCOME_SECTION } from 'core/constants';

import { IconLock } from 'components/icons/icon-lock/icon-lock';
import { OddsWithRank } from 'components/odds-with-rank/odds-with-rank';
import { getXsLabel } from 'helpers/stake-types';
import {
  getIsHighlighted,
  prepareMarketName,
  prepareOutcomeName,
  prepareHcp,
} from 'helpers/stake';
import { GA } from 'helpers/ga';

import './odd.scss';

const getSpecifierValue = (specifier, type) => {
  const result = specifier.split('|').find(item => item.split('=').includes(type));

  if (result) {
    return result.split('=')[1];
  }

  return null;
};

export class Odd extends Component {
  static propTypes = {
    stake: PropTypes.shape().isRequired,
    toggleStake: PropTypes.func.isRequired,
    eventId: PropTypes.string.isRequired,
    teamA: PropTypes.string.isRequired,
    teamB: PropTypes.string.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    type: PropTypes.oneOf([
      TOP_EVENTS_TYPES.ALL,
      TOP_EVENTS_TYPES.SPORT,
      TOP_EVENTS_TYPES.LEAGUE,
      TOP_EVENTS_TYPES.LIVE,
    ]).isRequired,
    markets: PropTypes.shape(),
    sportId: PropTypes.string.isRequired,
    leagueId: PropTypes.string.isRequired,
    isLive: PropTypes.bool.isRequired,
    marketId: PropTypes.number.isRequired,
    outcomeId: PropTypes.number.isRequired,
    isTotal: PropTypes.bool.isRequired,
    isHandicap: PropTypes.bool.isRequired,
    specifier: PropTypes.string.isRequired,
    variants: PropTypes.shape().isRequired,
    recommendationId: PropTypes.string,
  };

  static defaultProps = {
    markets: null,
    recommendationId: null,
  };

  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props, nextProps);
  }

  onClick = () => {
    const {
      stake: {
        k,
        b,
      },
      outcomeId,
      marketId,
      specifier,
      toggleStake,
      teamA,
      teamB,
      eventId,
      betSlipStakes,
      betSlipState,
      type,
      markets,
      sportId,
      leagueId,
      isLive,
      recommendationId,
    } = this.props;
    const isAllowed = k && k > 1 && b !== 1;
    const marketInfo = markets && markets[marketId];

    if (!isAllowed) {
      return;
    }

    const stake = {
      betFactor: k,
      specifier,
      outcomeId,
      teamA,
      teamB,
      eventId,
      marketId,
      marketName: prepareMarketName({
        teamA,
        teamB,
        specifier,
        marketId,
        marketInfo,
      }),
      outcomeName: prepareOutcomeName({
        teamA,
        teamB,
        specifier,
        outcomeId,
        marketInfo,
      }),
      sportId,
      leagueId,
      isLive,
    };
    const isHighlighted = getIsHighlighted(betSlipState, betSlipStakes, `${eventId}-${marketId}-${specifier}-${outcomeId}`);
    let label;
    let section;

    if (!isHighlighted) {
      if (recommendationId) {
        stake.recommendationId = recommendationId;
      }

      if (type === TOP_EVENTS_TYPES.ALL) {
        label = 'top-events-main';
        section = AI_OUTCOME_SECTION.MAIN_TOP;
      } else if (type === TOP_EVENTS_TYPES.SPORT) {
        label = 'top-events-sports';
        section = AI_OUTCOME_SECTION.SPORT_TOP;
      } else if (type === TOP_EVENTS_TYPES.LEAGUE) {
        label = 'top-events-league';
        section = AI_OUTCOME_SECTION.LEAGUE_TOP;
      } else if (type === TOP_EVENTS_TYPES.LIVE) {
        label = 'top-events-inplay';
        section = AI_OUTCOME_SECTION.INPLAY_TOP;
      }

      if (label) {
        GA.event({
          category: 'added-outcome',
          label,
        });
      }
    }

    if (section) {
      stake.section = section;
    }

    toggleStake(stake);
  };

  render() {
    const {
      stake: {
        k,
        b,
      },
      marketId,
      outcomeId,
      specifier,
      betSlipState,
      betSlipStakes,
      eventId,
      isTotal,
      isHandicap,
      variants,
    } = this.props;
    const isHighlighted = getIsHighlighted(betSlipState, betSlipStakes, `${eventId}-${marketId}-${specifier}-${outcomeId}`);
    const prepearedName = !isHandicap && !isTotal && getXsLabel(outcomeId);
    const originalName = !isHandicap && !isTotal && variants && variants[''][0].outcomes.find(({ id }) => id === outcomeId).name;
    const isAllowed = k && k > 1 && b !== 1;

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
        className={classNames('outcome-wrapper odd d-flex justify-content-between align-items-center px-1_5 text-small', {
          'is-highlighted': isHighlighted && isAllowed,
          'is-not-allowed': !isAllowed,
        })}
      >
        <span className="odd-label text-nowrap text-extra-2 pr-1">
          {isHandicap && (
            <FormattedMessage
              id={`line.handicap.${outcomeId}.short`}
              values={{ amount: prepareHcp(outcomeId, getSpecifierValue(specifier, 'hcp')) }}
            />
          )}
          {isTotal && <FormattedMessage id={`line.total.${outcomeId}.short`} values={{ amount: getSpecifierValue(specifier, 'total') }} />}
          {prepearedName || originalName}
        </span>
        <div className="d-flex align-items-center">
          {isAllowed
            ? (
              <OddsWithRank odds={k} className="rank-arrow font-weight-bold position-relative">
                {k}
              </OddsWithRank>
            )
            : <IconLock />}
        </div>
      </div>
    );
  }
}
