import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import {
  withCoreComponent,
  withLocale,
  withBetSlipActions,
  withBetSlip,
  withFeedbackActions,
} from 'core/hocs';
import { TopEvents as TopEventsCore } from 'core/components';
import {
  TOP_EVENTS_TYPES,
  BET_SLIP_STORE_FIELDS,
  BET_SLIP_STATE,
  RECOMMENDATIONS_GROUPS,
} from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { Carousel } from 'components/carousel/carousel';
import { MarketCard } from 'components/market-card/market-card';
import { IconRight } from 'components/icons/icon-right/icon-right';
import { TopEventsPreloader } from './top-events-preloader';

import './top-events.scss';

const settings = {
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  lazyLoad: 'ondemand',
  responsive: [
    {
      breakpoint: 586,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '20px',
      },
    },
    {
      breakpoint: 719,
      settings: {
        slidesToShow: 2.06,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 1007,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1529,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1921,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 2249,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

class TopEventsUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()),
    isInProgress: PropTypes.bool.isRequired,
    type: PropTypes.oneOf([
      TOP_EVENTS_TYPES.ALL,
      TOP_EVENTS_TYPES.SPORT,
      TOP_EVENTS_TYPES.LEAGUE,
      TOP_EVENTS_TYPES.LIVE,
    ]).isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    markets: PropTypes.shape(),
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
    recommendationsGroup: PropTypes.string.isRequired,
  };

  static defaultProps = {
    items: null,
    statuses: null,
    markets: null,
  };

  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  render() {
    const {
      locale,
      items,
      isInProgress,
      type,
      statuses,
      markets,
      logoUrl,
      logoUrl2,
      betSlipState,
      betSlipStakes,
      toggleStake,
      isLiveFrozen,
      isPrematchFrozen,
      sendEventClickFeedback,
      recommendationsGroup,
    } = this.props;
    const { isMounted } = this.state;
    const isAllType = type === TOP_EVENTS_TYPES.ALL;

    if (isMounted && items && !items.length && !isInProgress) {
      return null;
    }

    return (!isInProgress && isMounted)
      ? (
        <Fragment>
          <FormattedTag
            id={`general.${(isAllType || type === TOP_EVENTS_TYPES.LIVE) && recommendationsGroup === RECOMMENDATIONS_GROUPS.AI ? 'events-for-you' : 'top-events'}`}
            tag="p"
            className={classNames('section-title font-weight-bold mb-1 ml-2 ml-sm-0', { 'mt-2': !isAllType })}
          />

          <div className="top-events mb-2_5">
            {!isInProgress && items && !!items.length && (
              <Carousel
                settings={settings}
                iconLeft={<IconRight />}
                iconRight={<IconRight />}
              >
                {items.map(event => (
                  <div key={event.desc.id}>
                    <MarketCard
                      key={event.desc.id}
                      {...event}
                      locale={locale}
                      type={type}
                      statuses={statuses}
                      marketsInfo={markets}
                      logoUrl={logoUrl}
                      logoUrl2={logoUrl2}
                      betSlipState={betSlipState}
                      betSlipStakes={betSlipStakes}
                      toggleStake={toggleStake}
                      isLiveFrozen={isLiveFrozen}
                      isPrematchFrozen={isPrematchFrozen}
                      sendEventClickFeedback={sendEventClickFeedback}
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        </Fragment>
      )
      : <TopEventsPreloader className={classNames({ 'mt-2': !isAllType })} />;
  }
}

export const TopEvents = withFeedbackActions(
  withLocale(withCoreComponent(TopEventsCore, withBetSlipActions(withBetSlip(TopEventsUI, [
    BET_SLIP_STORE_FIELDS.STAKES,
    BET_SLIP_STORE_FIELDS.BET_SLIP_STATE,
  ]))))
);

