import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import store from 'store';
import { withCoreComponent, withLocale } from 'core/hocs';
import { InplayGames as InplayGamesCore } from 'core/components';
import { isEmptyOrNil } from 'core/helpers';
import { AI_OUTCOME_SECTION } from 'core/constants';

import { GA } from 'helpers/ga';
import { RouteLink } from 'components/route-link/route-link';
import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { GamesTable } from 'components/games-table/games-table';
import { GamesTablePreloader } from 'components/games-table/games-table-preloader/games-table-preloader';
import { PAGE_NAMES } from '../../constants';

import './inplay-games.scss';

const MAX_GAME_TABLES = 4;
const STORAGE_LIVE_IDS = 'closedLiveIds';

class InplayGamesUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    pageName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sportId: PropTypes.number,
    liveSportId: PropTypes.number,
    className: PropTypes.string,
    eventsCount: PropTypes.number,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    streams: PropTypes.shape(),
    markets: PropTypes.shape(),
    match: PropTypes.shape().isRequired,
    isFrozen: PropTypes.bool.isRequired,
    recommendations: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    sportId: null,
    liveSportId: null,
    className: null,
    eventsCount: null,
    statuses: null,
    streams: null,
    markets: null,
    recommendations: null,
  };

  state = {
    closedIds: this.props.pageName === PAGE_NAMES.LEAGUE ? [] : (store.get(STORAGE_LIVE_IDS) || []),
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {
          leagueId: prevLeagueId,
        }
      }
    } = prevProps;
    const {
      match: {
        params: {
          leagueId,
        }
      }
    } = this.props;

    if (prevLeagueId !== leagueId) {
      this.clearClosedIdsInState();
    }
  }

  clearClosedIdsInState = () => this.setState({ closedIds: [] });

  toggleOpenTable = (e) => {
    const { dataset: { tableId } } = e.currentTarget;
    const { closedIds } = this.state;
    const { pageName } = this.props;
    let label;
    let isExpanded;
    let currentClosedIds = closedIds;

    if (closedIds.length) {
      const isExistedId = closedIds.includes(tableId);

      if (isExistedId) {
        isExpanded = true;
        currentClosedIds = closedIds.filter(id => id !== tableId);
      } else {
        currentClosedIds = [...closedIds, tableId];
      }
    } else {
      currentClosedIds = [tableId];
    }

    this.setState({ closedIds: currentClosedIds });

    if (pageName !== PAGE_NAMES.LEAGUE) {
      store.set(STORAGE_LIVE_IDS, currentClosedIds);
    }

    if (pageName === PAGE_NAMES.HOME) {
      label = isExpanded ? 'main-inplay-expand' : 'main-inplay-collapse';
    } else if (pageName === PAGE_NAMES.LIVE) {
      label = isExpanded ? 'inplay-expand' : 'inplay-collapse';
    } else if (pageName === PAGE_NAMES.SPORT) {
      label = isExpanded ? 'sport-inplay-expand' : 'sport-inplay-collapse';
    } else if (pageName === PAGE_NAMES.LEAGUE) {
      label = isExpanded ? 'league-inplay-expand' : 'league-inplay-collapse';
    }

    if (label) {
      GA.event({
        category: 'expand-collapse',
        label,
      });
    }
  }

  render() {
    const {
      items,
      eventsCount,
      locale,
      pageName,
      sportId,
      liveSportId,
      className,
      statuses,
      logoUrl,
      logoUrl2,
      streams,
      markets,
      isFrozen,
      recommendations,
    } = this.props;
    const { closedIds } = this.state;
    const areGamesEmptyOrNil = isEmptyOrNil(items);
    const isInProgress = R.isNil(eventsCount) && !areGamesEmptyOrNil || R.isNil(markets);
    const isHomePage = pageName === PAGE_NAMES.HOME;
    const isSportPage = pageName === PAGE_NAMES.SPORT;
    const isLeaguePage = pageName === PAGE_NAMES.LEAGUE;
    const isLivePage = pageName === PAGE_NAMES.LIVE;
    let sectionBeforeEventPage = null;

    if (areGamesEmptyOrNil && !isInProgress) {
      return null;
    }

    if (isHomePage) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.MAIN_INPLAY;
    } else if (isLivePage) {
      if (liveSportId) {
        sectionBeforeEventPage = AI_OUTCOME_SECTION.INPLAY;
      } else {
        sectionBeforeEventPage = AI_OUTCOME_SECTION.ALL_SPORTS_INPLAY;
      }
    } else if (isSportPage) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.SPORT_INPLAY;
    } else if (isLivePage) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.LEAGUE_INPLAY;
    }

    return isInProgress
      ? <GamesTablePreloader withHeader={!isLivePage} className={className} />
      : (
        <div className={className}>
          {!isLivePage && (
            <div className="d-flex align-items-end justify-content-between mb-1 px-2 px-sm-0">
              <FormattedTag id="general.in-play" className="section-title font-weight-bold" />

              {isHomePage && !areGamesEmptyOrNil && items.length > MAX_GAME_TABLES && (
                <FormattedTag
                  id="general.see-all"
                  values={{ amount: null }}
                  tag={RouteLink}
                  to="/live"
                  locale={locale}
                  className="text-small text-extra-2"
                />
              )}
            </div>
          )}

          {!areGamesEmptyOrNil && (R.compose(
            R.map((games) => {
              const tableId = games[0].desc.tournament.id;
              const isClose = closedIds.includes(tableId);

              return (
                <GamesTable
                  key={`${tableId}`}
                  tableId={tableId}
                  games={games}
                  isLeagueType={isLeaguePage}
                  withTableTitle={!isLeaguePage}
                  pageName={pageName}
                  statuses={statuses}
                  markets={markets}
                  logoUrl={logoUrl}
                  logoUrl2={logoUrl2}
                  isLive
                  streams={streams}
                  toggleOpenTable={this.toggleOpenTable}
                  isOpen={!isClose}
                  isLiveFrozen={isFrozen}
                  sectionBeforeEventPage={sectionBeforeEventPage}
                  liveSportId={liveSportId}
                />
              );
            }),
            R.when(R.always(isLivePage && !liveSportId && !isEmptyOrNil(recommendations)), R.always(recommendations)),
            R.when(R.always(isLivePage && liveSportId), R.filter(games => games[0].desc.sport.id === liveSportId)),
            R.when(R.always(isHomePage || isSportPage), R.take(MAX_GAME_TABLES)),
          )(items))}

          {!areGamesEmptyOrNil && !isInProgress && items.length > MAX_GAME_TABLES && (isHomePage || isSportPage) && (
            <RouteLink
              to={isSportPage ? `/live/${sportId}` : '/live'}
              locale={locale}
              className="in-play-games-link d-flex align-items-center justify-content-center mt-1_5"
            >
              <FormattedTag id="general.see-all" values={{ amount: isHomePage ? eventsCount : null }} className="h6" />
            </RouteLink>
          )}
        </div>
      );
  }
}

export const InplayGames = withRouter(withLocale(withCoreComponent(InplayGamesCore, InplayGamesUI)));
