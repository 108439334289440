import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import qs from 'qs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getMainLineMarkets, isEmptyOrNil } from 'core/helpers';
import { TOP_EVENTS_TYPES, BET_SLIP_STATE, AI_OUTCOME_SECTION } from 'core/constants';

import { FormattedTag } from 'components/formatted-tag/formatted-tag';
import { RouteLink } from 'components/route-link/route-link';
import { TeamLogoPlaceholder } from 'components/team-logo-placeholder/team-logo-placeholder';
import { GameStatus } from 'components/betting-table/game-status/game-status';
import { IconArrowControl } from 'components/icons/icon-arrow-control/icon-arrow-control';
import { toTimeIfNearOrDate, removeComma, toTodayOrTomorrow } from 'helpers/date';
import { prepareMarketName } from 'helpers/stake';
import { prepareLogo } from 'helpers/logo';
import { GA } from 'helpers/ga';
import { prepareScore } from 'helpers/score';
import {
  SPORTS_WITH_3WAY_MAIN_MARKET,
  TODAY,
  TOMORROW,
  SPORTS_WITHOUT_LIVE_SCORE,
} from '../../constants';
import { Odd } from './odd/odd';

import './market-card.scss';

const SCROLL_OFFSET = 100;
export class MarketCard extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      TOP_EVENTS_TYPES.ALL,
      TOP_EVENTS_TYPES.SPORT,
      TOP_EVENTS_TYPES.LEAGUE,
      TOP_EVENTS_TYPES.LIVE,
    ]).isRequired,
    desc: PropTypes.shape().isRequired,
    score: PropTypes.shape().isRequired,
    state: PropTypes.shape().isRequired,
    markets: PropTypes.shape().isRequired,
    logoUrl: PropTypes.string.isRequired,
    logoUrl2: PropTypes.string.isRequired,
    statuses: PropTypes.shape(),
    marketsInfo: PropTypes.shape(),
    isLive: PropTypes.bool.isRequired,
    toggleStake: PropTypes.func.isRequired,
    betSlipStakes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    betSlipState: PropTypes.oneOf([
      BET_SLIP_STATE.DEFAULT,
      BET_SLIP_STATE.SUSPENDED,
      BET_SLIP_STATE.BET_FACTOR_DECREASED,
      BET_SLIP_STATE.SUCCESS,
      BET_SLIP_STATE.ERROR,
    ]).isRequired,
    isLiveFrozen: PropTypes.bool.isRequired,
    isPrematchFrozen: PropTypes.bool.isRequired,
    sendEventClickFeedback: PropTypes.func.isRequired,
    recommendationId: PropTypes.string,
  };

  static defaultProps = {
    statuses: null,
    marketsInfo: null,
    recommendationId: null,
  };

  oddsRef = createRef();

  resizeWindowSubsription;

  state = {
    isPrevShowed: false,
    isNextShowed: false,
  };

  componentDidMount() {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollWidth, clientWidth } = odds;
      this.resizeWindowSubsription = fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(this.onScroll);

      if (scrollWidth > clientWidth) {
        this.setIsNextShowed(true);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { markets: prevMarkets } = prevProps;
    const { markets } = this.props;

    if (!R.equals(prevMarkets, markets)) {
      this.onScroll();
    }
  }

  componentWillUnmount() {
    if (this.resizeWindowSubsription) {
      this.resizeWindowSubsription.unsubscribe();
    }
  }

  setIsNextShowed = isNextShowed => this.setState({ isNextShowed });

  setIsPrevShowed = isPrevShowed => this.setState({ isPrevShowed });

  onClick = () => {
    const {
      type,
      sendEventClickFeedback,
      desc,
      isLive,
      recommendationId,
    } = this.props;
    let label;

    if (type === TOP_EVENTS_TYPES.ALL) {
      label = 'top-events-main-to-event';
    } else if (type === TOP_EVENTS_TYPES.SPORT) {
      label = 'top-events-sports-to-event';
    } else if (type === TOP_EVENTS_TYPES.LEAGUE) {
      label = 'top-events-league-to-event';
    } else if (type === TOP_EVENTS_TYPES.LIVE) {
      label = 'top-events-inplay-to-event';
    }

    if (label) {
      GA.event({
        category: 'go-to-event-page',
        label,
      });
    }

    sendEventClickFeedback(desc.id, isLive, recommendationId);
  };

  onScroll = () => {
    const { isPrevShowed, isNextShowed } = this.state;

    if (this.oddsRef && this.oddsRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = this.oddsRef.current;
      const preparedWidth = Math.ceil(scrollLeft + clientWidth);

      if (isPrevShowed) {
        if (scrollLeft === 0) {
          this.setIsPrevShowed(false);
        }
      } else if (scrollLeft > 0) {
        this.setIsPrevShowed(true);
      }

      if (isNextShowed) {
        if (preparedWidth >= scrollWidth) {
          this.setIsNextShowed(false);
        }
      } else if (preparedWidth < scrollWidth) {
        this.setIsNextShowed(true);
      }
    }
  };

  onPrevClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft - SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onNextClick = () => {
    const odds = this.oddsRef && this.oddsRef.current;

    if (odds) {
      const { scrollLeft } = odds;
      odds.scrollTo({
        left: scrollLeft + SCROLL_OFFSET,
        behavior: 'smooth',
      });
    }
  };

  onTouch = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      locale,
      type,
      isLive,
      desc: {
        id: eventId,
        sport: {
          id: sportId,
          name: sportName,
        },
        tournament: {
          id: leagueId,
          name: leagueName,
        },
        competitors,
        scheduled,
        category,
      },
      score: {
        home_score: homeScore,
        away_score: awayScore,
        home_dismissals: homeDismissals,
        away_dismissals: awayDismissals,
      },
      state: {
        match_status: eventStatus,
        clock,
      },
      markets,
      logoUrl,
      logoUrl2,
      statuses,
      marketsInfo,
      betSlipState,
      betSlipStakes,
      toggleStake,
      isLiveFrozen,
      isPrematchFrozen,
      recommendationId,
    } = this.props;
    const { isPrevShowed, isNextShowed } = this.state;
    let is3way = SPORTS_WITH_3WAY_MAIN_MARKET.includes(sportId);
    const isFrozen = isLive ? isLiveFrozen : isPrematchFrozen;
    const timestamp = scheduled * 1000;
    const formattedDate = toTodayOrTomorrow(timestamp);
    const marketsList = getMainLineMarkets(markets, marketsInfo, sportId, isFrozen);
    const isAnyMarketExists = marketsList.length > 0;
    const isOneMarket = marketsList.length < 2;
    const teamA = competitors[0].name;
    const teamB = competitors[1].name;
    const teamALogo = prepareLogo({
      logoUrl,
      logoUrl2,
      competitorId: competitors[0].id,
      sportId,
      categorySlug: category.slug,
      countryCode: competitors[0].country_code,
    });
    const teamBLogo = prepareLogo({
      logoUrl,
      logoUrl2,
      competitorId: competitors[1].id,
      sportId,
      categorySlug: category.slug,
      countryCode: competitors[1].country_code,
    });
    const oddProps = {
      teamA,
      teamB,
      eventId,
      type,
      betSlipState,
      betSlipStakes,
      toggleStake,
      markets: marketsInfo,
      sportId,
      leagueId,
      isLive,
      recommendationId,
    };
    let sectionBeforeEventPage = null;

    if (type === TOP_EVENTS_TYPES.ALL) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.MAIN_TOP;
    } else if (type === TOP_EVENTS_TYPES.SPORT) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.SPORT_TOP;
    } else if (type === TOP_EVENTS_TYPES.LEAGUE) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.LEAGUE_TOP;
    } else if (type === TOP_EVENTS_TYPES.LIVE) {
      sectionBeforeEventPage = AI_OUTCOME_SECTION.INPLAY_TOP;
    }

    return (
      <div className="position-relative">
        <RouteLink
          to={`/event/${eventId}?${qs.stringify({
            isLive,
            sectionBeforeEventPage,
            recommendationId,
          }, { skipNulls: true })}`}
          locale={locale}
          onClick={this.onClick}
          className="market-card d-flex flex-column rounded px-1 py-1_5 pt-sm-2"
        >
          <div className="d-flex align-items-center justify-content-center mb-1">
            <TeamLogoPlaceholder imageUrl={teamALogo} sportId={sportId} className="market-card-logo" />
            <div className="market-card-date-wrapper d-flex justify-content-center">
              {isLive
                ? (
                  <div className="d-flex flex-column mt-n1 text-center">
                    {!SPORTS_WITHOUT_LIVE_SCORE.includes(sportId) && (
                      <div className="text-success font-weight-bold d-flex justify-content-center">
                        <span>{prepareScore(sportId, homeScore, homeDismissals)}</span>
                        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                        <span>{' - '}</span>
                        <span>{prepareScore(sportId, awayScore, awayDismissals)}</span>
                      </div>
                    )}

                    <GameStatus
                      locale={locale}
                      sportId={sportId}
                      eventStatus={eventStatus}
                      className="caption text-extra-2 text-break"
                      statuses={statuses}
                      clock={clock}
                    />
                  </div>
                )
                : (
                  <span className="market-card-date caption text-extra-2 text-center">
                    {formattedDate === TODAY && <FormattedTag id="general.today" className="d-block" />}
                    {formattedDate === TOMORROW && <FormattedTag id="general.tomorrow" className="d-block" />}
                    {removeComma(toTimeIfNearOrDate(timestamp, locale))}
                  </span>
                )}
            </div>
            <TeamLogoPlaceholder imageUrl={teamBLogo} sportId={sportId} className="market-card-logo" />
          </div>

          <div className="d-flex align-items-center justify-content-between h6">
            <div className="market-card-teams-name-wr d-flex align-items-center">
              <div className="market-card-teams-border flex-fill" />
              <div className="text-truncate ml-1">{teamA}</div>
            </div>

            <div> — </div>

            <div className="market-card-teams-name-wr d-flex align-items-center">
              <div className="text-truncate mr-1">{teamB}</div>
              <div className="market-card-teams-border flex-fill" />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center caption text-extra-3">
            <span className="flex-shrink-0">
              {sportName}
            </span>
            <span className="mr-0_5">:</span>
            <span className="text-truncate">
              {leagueName}
            </span>
          </div>
        </RouteLink>

        <div className="market-card-odds-wrapper position-absolute">
          <div
            ref={this.oddsRef}
            onScroll={this.onScroll}
            onTouchMove={this.onTouch} // TODO: should optimize?
            className="market-card-odds-scroll-bar d-flex"
          >
            {isAnyMarketExists ? (
              marketsList.map(({
                marketInfo,
                marketOutcome, isTotal, isHandicap, specifier
              }, index) => {
                const { id: marketId, variants } = marketInfo;
                const marketName = prepareMarketName({
                  marketInfo, teamA, teamB, specifier, marketId,
                });
                is3way = marketOutcome && Object.values(marketOutcome).length > 2;
                const isLastMarket = marketsList.length === index + 1;

                return !isEmptyOrNil(marketOutcome) && (
                  <div key={marketId} className={classNames('market-card-odds-item d-flex flex-column flex-shrink-0 position-relative', { 'is-one-market': isOneMarket, 'with-draw': is3way, 'mr-1_5': !isLastMarket })}>
                    <div className="market-name overflow-hidden caption position-absolute mt-1_5">
                      <span className="text-nowrap text-extra-3">
                        {marketName}
                      </span>
                    </div>
                    <div className="market-card-odds-group mt-4 flex-shrink-0">
                      {Object.entries(marketOutcome).map(([outcomeId, outcomeValue]) =>
                        outcomeValue ? (
                          <Odd
                            key={`${eventId}-${marketId}-${specifier}-${outcomeId}`}
                            stake={outcomeValue}
                            outcomeId={outcomeId}
                            marketId={marketId}
                            isTotal={isTotal}
                            isHandicap={isHandicap}
                            specifier={specifier}
                            variants={variants}
                            {...oddProps}
                          />
                        ) : (
                          <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                            &#8212;
                          </div>
                        ))}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={classNames('market-card-odds-item w-100', { 'with-draw': is3way })}>
                <div className="market-card-odds-group">
                  <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                    &#8212;
                  </div>
                  {is3way && (
                    <div className="odd is-not-allowed d-flex justify-content-center align-items-center text-extra-3">
                      &#8212;
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={classNames('market-card-odds-prev position-absolute d-flex align-items-center pl-0_5', { invisible: !isPrevShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onPrevClick}
              onKeyPress={this.onPrevClick}
              className="market-card-odds-prev-btn d-flex justify-content-center align-items-center pt-2_5"
            >
              <IconArrowControl className="d-none d-sm-block" />
            </div>
          </div>

          <div className={classNames('market-card-odds-next position-absolute d-flex align-items-center justify-content-end pr-0_5', { invisible: !isNextShowed })}>
            <div
              role="button"
              tabIndex="0"
              onClick={this.onNextClick}
              onKeyPress={this.onNextClick}
              className="market-card-odds-next-btn d-flex justify-content-center align-items-center pt-2_5"
            >
              <IconArrowControl className="d-none d-sm-block" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
